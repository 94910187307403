import React, { useState } from "react";
import * as Yup from "yup";
import { Link, navigate } from "gatsby";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import BlankLayout from "../../components/layouts/BlankLayout";
import { createUser } from "../../helpers/supabase";
import swal from "sweetalert";
import SEO from "../../components/layouts/SEO";
import { useFormik } from "formik";
import useBoolean from "../../hooks/useBoolean";
import Switch from "../../components/UI/Switch";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(4).label("Name"),
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(8).label("Password"),
});

const RegistrationPage: React.FC = () => {
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useBoolean();
  const [isAgree, setAgree] = useBoolean();
  const { errors, handleChange, handleBlur, handleSubmit, values } = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
    },
    validationSchema,
    onSubmit: async ({ name, email, password }) => {
      if (!isAgree) return;

      setLoading.on();
      setServerError("");

      try {
        await createUser({ name, email, password });
        navigate("/auth/login");
        swal(
          "You have successfully registered!",
          "Confirm your email before login",
          "success"
        );
        setLoading.off();
      } catch (error) {
        setServerError((error as any).message);
        setLoading.off();
      }
    },
  });

  return (
    <BlankLayout>
      <SEO
        title="Register | Ensemble"
        url="https://ensembleai.io/auth/register"
        desc="we help enterprise organisations build and run advanced data, analytics and AI capabilities based on modern cloud-native technology."
        ogImage="https://ensembleai.io/assets/img/home.png"
      />
      <div className="w-full max-w-none md:max-w-[480px] md:bg-white md:shadow-4xl p-6 md:p-10 rounded-2xl flex flex-col gap-6 mt-[72px] md:m-0">
        <h1 className="text-[22px] font-semibold text-black-light text-center">
          Registration
        </h1>
        <p className="text-sm text-black-light text-center">
          We offer a 30 day free trial to help you understand the potential of advanced analytics and AI as applied to your business.  Complete the form below to create your demo environment.{" "}
        </p>
        <form onSubmit={handleSubmit}>
          {serverError && (
            <div className="py-6 text-center">
              <p className="text-error text-sm">{serverError}</p>
            </div>
          )}
          <div className="flex flex-col items-center space-y-6">
            <Input
              inputProps={{ name: "name" }}
              type="text"
              placeholder="Your name"
              label="Name"
              className="!w-full"
              onHardChange={handleChange}
              onBlur={handleBlur}
              value={values.name ?? ""}
              error={errors.name ?? ""}
              isRequired
            />
            <Input
              inputProps={{ name: "email" }}
              type="text"
              placeholder="Email"
              label="Email"
              className="!w-full"
              onHardChange={handleChange}
              onBlur={handleBlur}
              value={values.email ?? ""}
              error={errors.email ?? ""}
              isRequired
            />
            <Input
              inputProps={{ name: "password" }}
              type="password"
              placeholder="Password"
              label="Password"
              className="!w-full"
              onHardChange={handleChange}
              value={values.password ?? ""}
              error={errors.password ?? ""}
              isRequired
            />
            <div className="flex items-center gap-4 w-full">
              <Switch
                isEnabled={isAgree}
                onChange={setAgree.toggle}
                color="red"
                size="small"
              />
              <span className="text-sm text-black-light">
                Accept Terms & Conditions Of Use
              </span>
            </div>
            <Button
              label="Free Trial"
              actionType="submit"
              loading={loading}
              disabled={!isAgree}
              className="!w-full md:!w-[172px]"
            />
          </div>
        </form>
      </div>
    </BlankLayout>
  );
};

export default RegistrationPage;
