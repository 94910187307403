import React from "react";
import useWindowSize from "../../hooks/useWindowSize";

import Logo from "../logo";

interface IBlankLayoutProps {
  children: React.ReactNode;
}

const BlankLayout: React.FC<IBlankLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-grey-500 w-full relative flex items-center justify-center">
      <header className="header pt-6 md:pt-16 flex justify-center absolute top-0">
        <Logo isBlack />
      </header>
      <div className="w-full flex justify-center z-10">{children}</div>
    </div>
  );
};

export default BlankLayout;
